import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid, TextField, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import {Link, StaticQuery} from 'gatsby';
import { navigate } from 'gatsby-link';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import gsap from 'gsap';

gsap.registerPlugin(ScrollToPlugin)


const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 300px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 100px;
  text-align: left;
  padding-left: 80px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    margin: 5rem 0;
  }
`

const StorySection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
  }
`

const StoryContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 7rem;
  position: relative;
  padding-left: 80px;

  .top {
    width: 85%;
  }

  .links {
    margin-top: 30px;
  }

  .stickers {
    margin-top: 30px;
  }

  .submit-button {
    margin-top: 80px;
  }

  .select {
    margin: 60px 0;
  }

  h2 {
    color: ${props => props.theme.colors.primary};
    width: 450px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 30px;


    &.caption {
    color: ${props => props.theme.colors.body};
    }
  }

  h4 {
    color: ${props => props.theme.colors.body};
    text-align: left;
    margin: 100px 0 40px 0;
  }

  h5 {
    color: ${props => props.theme.colors.primary};
    text-align: left;
    margin-bottom: 30px;
    font-weight: 300;

    .emp {
      font-weight: 500;
      color: ${props => props.theme.colors.body};
      font-size: inherit;
    }
  }

  p {
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
    text-align: left;
    line-height: 1.2;
    font-size: 2rem;
    margin-bottom: 20px;

    * {
    color: ${props => props.theme.colors.primary};
    font-size: inherit;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    padding-left: 0;

    .caption {
      font-size: 3rem;
    }

    h2 {
      width: 90%;
    }

    h4 {
      margin-top: 55px;
    }
  }
`

const Sticker = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.body};
  text-align: center;
  padding: 80px 0;
  cursor: pointer;

  h6 {
    
  }

  p {
    color: ${props => props.theme.colors.dark};
    text-align: center;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    height: 180px;
    width: 180px;

    h6 {
      font-size: 1.5rem;
    }

    p{
      font-size: 1.5rem;
    }
  }

  @media ${props => props.theme.breaks.down('smp')} {
    height: 140px;
    width: 140px;
    padding: 50px 10px;

    h6 {
      font-size: 1.3rem;
    }

    p{
      font-size: 1.3rem;
    }
  }
`

const Form = styled.form`
  color: ${props => props.theme.colors.body};

  & * {
  color: ${props => props.theme.colors.body};

  }

  .MuiFormLabel-root {
  color: ${props => props.theme.colors.body};
  }

  .MuiSelect-filled {
    background-color: ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.dark} !important;
    font-size: 1.8rem;
  }

  .MuiSelect-filled:focus {
    background-color: ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.dark} !important;
  }

  .MuiFilledInput-input {
    padding: 27px 12px 20px;
  }

  .MuiSelect-iconFilled {
    fill: ${props => props.theme.colors.dark};
  }


  .MuiInputBase-input {
    border-bottom: 2px solid ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.primary};
  }
`

const FileButton = styled.label`
  display: block;
  width: 100%;
  padding: 15px;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  transition: all .3s;
  cursor: pointer;
  position: relative;

  span {
    color: ${props => props.theme.colors.dark};
  }
  


  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }

  .file-name {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-weight: 300;
    color: ${props => props.theme.colors.primary};
    display: inline-block;
    width: 200px;
    text-align: left;
  }
`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 100%;
  border: none;
  padding: 15px 0;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px auto 0 auto;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`


const JoinTheTeamPage = ({ data }) => {

  function encode(data) {
    const formData = new FormData()
  
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
  }

  const [state, setState] = useState({dropdown: 'Graphic Designer', attachment: {}});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/.netlify/functions/multipart-file-upload', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  const scrollToSection = (pos) => {
    gsap.to(window, {scrollTo: {y: `.pos-3`, offsetY: 80}, duration: 1})
  }


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="Join the team.">
      <Container>
        
        <StorySection className="point-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>join the team</TitleCond>
          </div>

        

          <StoryContainer>
            <h2 className="caption">This is Celebrand.
              A branding agency
              in Monroe, NY, </h2>

              <div className="top">

              <p>with an office designed for creativity and a culture developed to encourage growth and happiness. We’ve got snacks and books (can we order something you want?). We’ve got a Quiet Room and comfy couches. And we offer scheduling and vacation that’s flexible, so you can live your life and do your best.</p>

              <h5>We care about creativity, <span className="emp">not</span>  disturbing your work-life balance.
                We care about trying new ideas, <span className="emp">not</span> focusing on mistakes.
                We care about supporting you.</h5>

                <p>Here are our open opportunities for <strong>in-house jobs</strong>. When can you start?</p>

                <h2>current jobs</h2>
            </div>

            <Grid container className="stickers" spacing={3}>
              <Grid item xs={6} lg={3}><Sticker onClick={() => scrollToSection('pos-1')}><h6>Graphic Designer</h6><p>(Full-Time)</p></Sticker></Grid>
              <Grid item xs={6} lg={3}><Sticker onClick={() => scrollToSection('pos-2')}><h6>Researcher</h6><p>(Full-Time)</p></Sticker></Grid>
              <Grid item xs={6} lg={3}><Sticker onClick={() => scrollToSection('pos-3')}><h6>Content Manager</h6><p>(Full-Time)</p></Sticker></Grid>
              <Grid item xs={6} lg={3}><Sticker onClick={() => scrollToSection('pos-4')}><h6>Social Media Manager</h6><p>(Full-Time/Part-Time)</p></Sticker></Grid>
            </Grid>

            {/* section 1 */}

            <h4 className="pos-1">Graphic Designer (Full-Time)</h4>

            <p>We’re looking for somebody with a good eye and the ability to design in different styles, flavors, and mediums. We’re a branding agency, so you’ll be designing for multiple brands and balancing different projects: websites, logos, brand books, color schemes, advertisements, and more. Come work in a space looking to support your best work.</p>

            <p><strong>What Do You Need?</strong></p>

            <p><strong>Experience.</strong> Show us your best work. Show us versatility and style.</p>

            <p><strong>Knowledge.</strong> We need somebody who can work with Adobe’s Creative Suite, including Photoshop, Illustrator, and XD.</p>

            <p><strong>Creativity.</strong> More than skills, we look for somebody who can come up with crazy ideas and crazy-good ones, too.</p>


            {/* section 2 */}

            <h4 className="pos-2">Researcher (Full-Time)</h4>

            <p>We’re a branding agency, which means we work with a lot of different industries. We want somebody who can use a combination of digital and in-person research to discover target markets, create buyer personas, and analyze local business reputations across multiple industries.
            </p>

            <p><strong>What Do We Offer?</strong></p>

            <p><strong>Support.</strong> Let’s get the books you need on our shelves, the snacks you want in our cabinets, and the support you need to be able to succeed and grow in your role.</p>

            <p><strong>Understanding.</strong>  Come work in a friendly environment devoted to your success, with comfortable chairs and couches. We also have a quiet room.
            </p>

            <p><strong>Flexibility.</strong>  Build a daily schedule and vacation structure that helps you work well and relax well, too. We’d prefer to have you in-house, but we’re willing to work with your job needs.
            </p>
            <br />
            <br />

            <p><strong>What Do We Expect?</strong></p>

            <p><strong>Experience.</strong> You know how to research, in-person and online.
            </p>

            <p><strong>Enthusiasm.</strong>  You’re willing to learn.
            </p>

            <p><strong>Results.</strong> Show us you’re good at what you do.
            </p>

            <p>If you’re the researcher we’re looking for, please get in touch.</p>

            {/* section 3 */}


            {/* <h4 className="pos-3">Sales Representative (Full-Time)</h4>

            <p>We’re looking for somebody with understanding. Can you speak to potential clients and understand their pain points, can you make them feel understood, and can you help them understand why we’re exactly what they need? Be ready to work in-house and on the road, finding clients that will do well with us. Understanding is just as much saying no to the wrong client as it is saying yes to the right client. If this sounds like you, come join a company committed to your growth and success.</p>

            <p><strong>Qualifications:</strong></p>

            <p>Drive to learn about branding and to keep growing in your sales skills.
              Tough skin and ability to handle rejection (you know, being a sales rep).
              Hunger for big clients and big numbers.
              </p>

            <p><strong>Responsibilities:</strong></p>

            <p>Communicating our values and creating enthusiastic buyers.
              Bringing in new clients that fit our target profile.
              Maintaining relationships and scheduling meetings with clients.</p> */}

        <h4 className="pos-3">Content Manager (Full-Time)</h4>

        <p>We're looking for somebody with understanding. A writer-slash-manager who can hop into different voices, express things in great detail or powerful simplicity, and who doesn't like repeating themselves. This position can be in-person or remote, as long as it's done well. We need somebody who's organized and motivated. Show us amazing work that you've done for clients or else amazing work that you've done and wish somebody would take.</p>

        <p><strong>What We Like to See:</strong></p>

        <p><strong>Boldness.</strong> You know how to make language pop.</p>

        <p><strong>Knowledge.</strong> You understand what works in branding.</p>

        <p><strong>Value.</strong> You earn your audience's attention, every single time.</p>

            {/* section 4 */}


            <h4 className="pos-4">Social Media Manager (Full-Time/Part-Time)</h4>

            <p>We’re a branding agency and we’re looking for an in-house social media manager with a talent for keeping a number of different accounts running. You’d be running socials for us and for our clients, so you should be able to work in different voices and styles. Our biggest markets are currently Instagram, LinkedIn, and Facebook, so we’ll need to see your work there. And if you can convince us why and how we should be concentrating elsewhere, then you’re already on the right path.</p>

            <p><strong>What Should You Show Us?</strong></p>

            <p><strong>Noise.</strong> We need somebody who can bring in numbers — we want to see your skill with organic growth.</p>

            <p><strong>Versatility.</strong> Being able to write in different voices and to find success on multiple platforms.</p>

            <p><strong>Organization.</strong> For all the things you need to keep track of, we need to know you’re not the type of person who lets tasks fall by the wayside.</p>

            <h2>apply now</h2>


            <Form 
              name="join-team"
              method="post"
              action="/submitted"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="join-team" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>

            <Grid container className="select">
              <Grid item xs={12} lg={3}>
              <FormControl variant="filled" fullWidth  defaultValue="Graphic Designer">
                <Select
                  name="dropdown"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={state.dropdown}
                  onChange={handleChange}
                >
                  <MenuItem value="Graphic Designer">Graphic Designer</MenuItem>
                  <MenuItem value="Marketing Strategist & Researcher">Marketing Strategist & Researcher</MenuItem>
                  <MenuItem value="Content Manager">Content Manager</MenuItem>
                  <MenuItem value="Social Media Manager">Social Media Manager</MenuItem>
                </Select>
              </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={4}><TextField fullWidth name="name" label="Your name" placeholder="Your name" color="secondary" value={state.name} onChange={handleChange} required></TextField></Grid>
              <Grid item xs={12} md={4}><TextField fullWidth name="company" label="Your company" placeholder="Your company" value={state.company} onChange={handleChange} color="secondary" required></TextField></Grid>
              <Grid item xs={12} md={4}><TextField fullWidth name="email" label="Your email" placeholder="Your email" value={state.email} onChange={handleChange} type="email" color="secondary" required></TextField></Grid>
            </Grid>

            <Grid className="submit-button" container spacing={5}>
              <Grid item lg={6} xs={12}>
                <FileButton file={state.attachment} htmlFor="import-button">
                  <input
                      id="import-button"
                      name="attachment"
                      // inputProps={{
                      //   accept:
                      //     ".csv",
                      // }}
                      onChange={handleAttachment}
                      style={{display: "none"}}
                      type="file"
                  />
                  <span>Upload resume and cover letter</span>
                  <span className="file-name">{state.attachment.name || ''}</span>
              </FileButton>
              </Grid>
              <Grid item lg={6} xs={12}><Button>Submit</Button></Grid>
            </Grid>
          </Form>
          </StoryContainer>
          
        
         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

         </StorySection>

        
      </Container>
      </Layout>
    
)
}

export default JoinTheTeamPage;